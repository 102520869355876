import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Hollow Rock 3x:30 & Superman Extensions 3×10/side`}</p>
    <p>{`then,`}</p>
    <p>{`Deadlifts 4×4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-KB SDHP’s (53/35)`}</p>
    <p>{`50ft Waiters Walk, Right Arm`}</p>
    <p>{`50ft Farmers Walk, Left Arm`}</p>
    <p>{`15-KB SDHP’s`}</p>
    <p>{`50ft Waiters Walk, Left Arm`}</p>
    <p>{`50ft Farmers Walk, Right Arm`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 Row/BIke/Jog at moderate pace.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      